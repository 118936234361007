import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Styled from './App.style';
import { Main } from '../Layout';
import { ThemeProvider } from 'styled-components';
import { Button } from '@savewatersavemoney/swsm-ui';
import ClearCache from 'react-clear-cache';
import { getVariable, utmCheck, renderCdnUrl } from '../../helpers';
import { isIE } from 'react-device-detect';

const theme = {
  flexboxgrid: {
    outerMargin: 1,
    container: {
      sm: 46,
      md: 61,
      lg: 74,
    },
  },
};

const getUrl = () => {
  const env = getVariable('mode', 'env');

  if (env === 'dev') {
    return 'https://cdn.getwaterfit.co.uk/integration/portal/develop';
  }
  else if (env === 'stage') {
    return 'https://cdn.getwaterfit.co.uk/integration/portal/stage';
  }
  else if (env === 'stage-au') {
    return 'https://cdn.smartwateradvice.getwaterfit.com/integration/portal/stage';
  }
  else if (env === 'aus') {
    return 'https://cdn.smartwateradvice.getwaterfit.com/integration/portal/latest';
  }
  else if (env === 'stage-usa') {
    return 'https://cdn.awe.getwaterfit.com/integration/portal/stage';
  }
  else if (env === 'usa') {
    return 'https://cdn.awe.getwaterfit.com/integration/portal/latest';
  }

  return 'https://cdn.getwaterfit.co.uk/integration/portal/latest';
};

const App: React.FC = () => {
  React.useEffect(() => {
    utmCheck();
  });

  return isIE ? (
    <Styled.IEWrap>
      <Styled.Item>
        <img height="50px" src={renderCdnUrl('logos/www-color.svg')} alt="GetWaterFit" />
        <h1>You seem to be using a unsupported browser.</h1>
        <h2>Please use a supported browser to improve your experience.</h2>
        <Styled.Logos>
          <a
            rel="noopener noreferrer"
            href="https://www.google.co.uk/chrome/?brand=CHBD&gclid=Cj0KCQiAoNWOBhCwARIsAAiHnEhBDarv-Wxk5QVIltImhFceXiD_e-BDQ95YwVCqWCm1UruC4G7RrswaAhwBEALw_wcB&gclsrc=aw.ds"
            target="_blank"
          >
            <img width="50px" src={renderCdnUrl('images/browser-logo/chrome.svg')} alt="Chrome" />
          </a>
          <a target="_blank" href="https://www.mozilla.org/en-GB/firefox/new/" rel="noopener noreferrer">
            <img width="50px" src={renderCdnUrl('images/browser-logo/firefox.svg')} alt="Firefox" />
          </a>
          <a target="_blank" href="https://www.microsoft.com/en-us/edge" rel="noopener noreferrer">
            <img width="50px" src={renderCdnUrl('images/browser-logo/edge.png')} alt="Edge" />
          </a>
        </Styled.Logos>
      </Styled.Item>
    </Styled.IEWrap>
  ) : (
    <>
      <ClearCache duration={9999999999} basePath={getUrl()}>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <>
            {!isLatestVersion && (
              <Styled.Wrap>
                <Styled.Box>
                  <p>
                    Looks like a new version of the app has been released. Please update in order to receive the latest
                    features.
                  </p>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      emptyCacheStorage();
                      window.location.reload();
                    }}
                  >
                    Update version
                  </Button>
                </Styled.Box>
              </Styled.Wrap>
            )}
          </>
        )}
      </ClearCache>
      <Styled.NormalizeWithStyles id="App">
        <Styled.Global />
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/" component={Main} />
          </Switch>
        </ThemeProvider>
      </Styled.NormalizeWithStyles>
    </>
  );
};

export default App;
