import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, LabelList, Cell } from 'recharts';
import { prettifyIfNumber, convertWater } from '../../../helpers';
import { ChartData } from '../../../interfaces';
import Styled from './ChartMultiBars.style';
import { useTranslation } from 'react-i18next';

interface ChartMultiBarsProps {
  id: number;
  chartData: ChartData;
  theme?: string;
  defaultLanguage?: string;
  isDesktop: boolean;
}

const ChartMultiBars: React.FC<ChartMultiBarsProps> = props => {
  const { chartData, defaultLanguage, isDesktop } = props;
  const { dataSets } = chartData;
  const dataLength = Object.keys(dataSets).length;
  const key = Object.keys(dataSets);
  const [display, setDisplay] = React.useState(key[0]);

  const [t] = useTranslation('common');

  const transformedData = chartData.dataSets.use?.data.filter(item => !(item.value === 0 && item.savingValue === 0));
  const transformedDataCost = chartData.dataSets.cost?.data.filter(
    item => !(item.value === 0 && item.savingValue === 0),
  );
  const transformedDataEnergy = chartData.dataSets.energy?.data.filter(
    item => !(item.value === 0 && item.savingValue === 0),
  );
  const transformedDataCarbon = chartData.dataSets.carbon?.data.filter(
    item => !(item.value === 0 && item.savingValue === 0),
  );
  const newTransformedData = transformedData?.map(el => {
    const o = Object.assign({}, el);
    const convertedValue = convertWater(defaultLanguage, o.value, false);
    const convertedSavingValue = convertWater(defaultLanguage, o.savingValue, false);
    const convertedOriginalValue = convertWater(defaultLanguage, o.originalValue, false);
    o.value = parseInt(convertedValue, 10);
    o.savingValue = parseInt(convertedSavingValue, 10);
    o.difference = parseInt(convertedOriginalValue, 10) - parseInt(convertedValue, 10);
    return o;
  });
  const newTransformedDataCost = transformedDataCost?.map(el => {
    const o = Object.assign({}, el);
    const convertedValue = convertWater(defaultLanguage, o.value, false);
    const convertedSavingValue = convertWater(defaultLanguage, o.savingValue, false);
    const convertedOriginalValue = convertWater(defaultLanguage, o.originalValue, false);
    o.value = parseInt(convertedValue, 10);
    o.savingValue = parseInt(convertedSavingValue, 10);
    o.difference = parseInt(convertedOriginalValue, 10) - parseInt(convertedValue, 10);
    return o;
  });
  const newTransformedDataEnergy = transformedDataEnergy?.map(el => {
    const o = Object.assign({}, el);
    const convertedValue = convertWater(defaultLanguage, o.value, false);
    const convertedSavingValue = convertWater(defaultLanguage, o.savingValue, false);
    const convertedOriginalValue = convertWater(defaultLanguage, o.originalValue, false);
    o.value = parseInt(convertedValue, 10);
    o.savingValue = parseInt(convertedSavingValue, 10);
    o.difference = parseInt(convertedOriginalValue, 10) - parseInt(convertedValue, 10);
    return o;
  });

  const newTransformedDataCarbon = transformedDataCarbon?.map(el => {
    const o = Object.assign({}, el);
    const convertedValue = convertWater(defaultLanguage, o.value, false);
    const convertedSavingValue = convertWater(defaultLanguage, o.savingValue, false);
    const convertedOriginalValue = convertWater(defaultLanguage, o.originalValue, false);
    o.value = parseInt(convertedValue, 10);
    o.savingValue = parseInt(convertedSavingValue, 10);
    o.difference = parseInt(convertedOriginalValue, 10) - parseInt(convertedValue, 10);
    return o;
  });

  const getChartData = () => {
    if (display === 'use') {
      return newTransformedData;
    }
    else if (display === 'energy') {
      return newTransformedDataEnergy;
    }
    else if (display === 'carbon') {
      return newTransformedDataCarbon;
    }
    else if (display === 'cost') {
      return newTransformedDataCost;
    }
  };

  const renderCustomizedLabel = props => {
    const { x, y, height, width, value } = props;
    const radius = 10;
    return (
      <text
        x={x + width / 2}
        y={y - radius}
        width={width}
        height={height}
        fill="#0F3E5D"
        style={{ fontWeight: 700 }}
        textAnchor="middle"
      >
        <tspan x={x + width / 2} dy="0em">
          {prettifyIfNumber(value)}
        </tspan>
      </text>
    );
  };
  const renderXAxis = props => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} style={{ transform: isDesktop ? 'rotate(0deg)' : 'rotate(-13deg)' }}>
          <tspan textAnchor="middle" x="0" style={{ fontSize: '13px' }}>
            {t(payload.value)}
          </tspan>
        </text>
      </g>
    );
  };

  const getYlabels = () => {
    if (display === 'energy') {
      const label = chartData.dataSets.energy?.yLabel;
      return label && t(label);
    }
    else if (display === 'carbon') {
      const label = chartData.dataSets.carbon?.yLabel;
      return label && t(label);
    }
    else if (display === 'cost') {
      const label = chartData.dataSets.cost?.yLabel;
      return label && t(label);
    }
    const label = chartData.dataSets.use?.yLabel;
    return label && t(label);
  };

  return (
    <>
      {dataLength > 1 ? (
        <Styled.ToggleWrap>
          {dataSets.energy && (
            <Styled.Item className={display === 'energy' ? 'active' : 'inactive'} onClick={() => setDisplay('energy')}>
              {t('data.chartGroup.5.title').toUpperCase()}
            </Styled.Item>
          )}
          {dataSets.use && (
            <Styled.Item className={display === 'use' ? 'active' : 'inactive'} onClick={() => setDisplay('use')}>
              {defaultLanguage === 'en-US'
                ? t('portal.my_use.chart_selector_option_gallon').toUpperCase()
                : t('portal.my_use.chart_selector_option_litre').toUpperCase()}{' '}
            </Styled.Item>
          )}
          {dataSets.carbon && (
            <Styled.Item className={display === 'carbon' ? 'active' : 'inactive'} onClick={() => setDisplay('carbon')}>
              {t('data.chartGroup.6.title').toUpperCase()}
            </Styled.Item>
          )}
          {dataSets.cost && (
            <Styled.Item className={display === 'cost' ? 'active' : 'inactive'} onClick={() => setDisplay('cost')}>
              {t('portal.my_use.chart_selector_option_cost').toUpperCase()}
            </Styled.Item>
          )}
        </Styled.ToggleWrap>
      ) : null}

      {display === 'cost' && (
        <p style={{ margin: '0px 0px 16px 0px' }}>
          <span
            style={{
              background: '#b2ebb1',
              border: '1px solid #7fc87d',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '8px',
              color: '#076804',
            }}
          >
            {t('portal.my_use.potential_savings')}:{' '}
            <b style={{ margin: '0px  4px' }}>
              {defaultLanguage === 'en-GB' ? '£' : null}
              {defaultLanguage === 'en-US' || defaultLanguage === 'en-AU' || defaultLanguage === 'en-CA' ? '$' : null}
              {transformedDataCost &&
                transformedDataCost.reduce((accumulator, currentObject) => {
                  const value = currentObject.value - currentObject.savingValue;
                  return accumulator + value;
                }, 0)}
            </b>
          </span>
        </p>
      )}

      <Styled.Wrap>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Styled.VerticalLine>
            <p
              style={{
                transform: 'rotate(270deg)',
                width: '70px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 0 26px 0',
              }}
            >
              {getYlabels()}
            </p>
          </Styled.VerticalLine>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ResponsiveContainer height={350} width="100%">
              <BarChart
                data={getChartData()}
                height={400}
                margin={{
                  top: 50,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <XAxis dataKey={'label'} tick={renderXAxis} minTickGap={0} interval={0} />

                <Bar
                  name="value"
                  maxBarSize={50}
                  barSize={50}
                  radius={[4, 4, 0, 0]}
                  dataKey="value"
                  minPointSize={3}
                  stackId="a"
                  className="bar-movement"
                >
                  <LabelList dataKey="value" content={renderCustomizedLabel} position="top" />
                  ((
                  {getChartData()?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={'#0b2641'} />
                  ))}
                </Bar>
                <Bar
                  name="difference"
                  className="original-values bar-movement"
                  dataKey="difference"
                  radius={[4, 4, 0, 0]}
                  stackId="a"
                  style={{ opacity: '0.3' }}
                >
                  ((
                  {getChartData()?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={'#0b2641'} />
                  ))}
                </Bar>

                <Bar barSize={50} className="bar-movement" dataKey="savingValue" fill="#25b3eb" radius={[4, 4, 0, 0]}>
                  <LabelList dataKey="savingValue" content={renderCustomizedLabel} position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>

            <Styled.HorizontalLine></Styled.HorizontalLine>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px', marginBottom: '16px' }}>
          <div style={{ display: 'flex' }}>
            {' '}
            <Styled.PieLegendDot style={{ backgroundColor: '#0b2641' }} />
            {t('chart-bar-legend-title-current')}
          </div>
          <div style={{ display: 'flex' }}>
            <Styled.PieLegendDot style={{ backgroundColor: '#25b3eb' }} />
            {t('chart-bar-legend-title-potential')}
          </div>{' '}
        </div>
      </Styled.Wrap>
    </>
  );
};

export default ChartMultiBars;
