import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const Card = styled.div`
  position: relative;
  background: white;
  margin-bottom: 8px;
  padding: 16px 8px;
  min-height: 100%;
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  transition: transform 0.6s;
  transform: ${props => (props.flipped ? 'rotateY(180deg)' : 'rotateY(0)')};
  transform-style: preserve-3d;
  width: 100%;

  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const Title = styled.h4`
  color: #0b2641;
  font-size: 16px;
  margin: 0;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 32px;
`;
const desktopStyles = css`
  border: 4px solid #d7f1ff;
  padding: 16px;
  border-radius: 10px;
`;
const Info = styled.div`
  background: #d7f1ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 2px solid #5eb1de;
  cursor: pointer;
  top: 8px;
  right: 8px;
  font-family: 'Times New Roman';
  font-size: 20px;
`;
const Desc = styled.p`
  text-align: center;
`;

const Panel = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ButtonComp = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  min-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  margin-bottom: 8px;
  transition: all 200ms ease-in-out;
  background: rgba(215, 241, 254, 1);
  color: ${colors.primary};
  border: 1px solid rgba(33, 170, 232, 1);
  &:hover {
    background: rgba(215, 241, 254, 0.9);
  }

  p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export default { Card, Title, Info, Desc, Panel, ButtonComp, ButtonWrap };
