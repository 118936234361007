import { PortalQuestionsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import store from 'store2';
const initialState: PortalQuestionsReducer = {
  getQuestions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  setQuestions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  answers: {},
};
const portalQuestionsReducer = createReducer(initialState, {
  GET_PORTAL_QUESTIONS_PENDING: state => {
    state.getQuestions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_PORTAL_QUESTIONS_FULFILLED: (state, action) => {
    state.getQuestions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getQuestions.data = action.payload.data;
  },
  GET_PORTAL_QUESTIONS_REJECTED: (state, action) => {
    state.getQuestions.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getQuestions.data = action.payload.data;
  },
  UPDATE_PORTAL_QUESTIONS: (state, action) => {
    store('swsm-myuse-qa', {
      answers: {
        ...state.answers,
        [action.key]: action.value,
      },
    });
    state.getQuestions.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.answers = { ...state.answers, [action.key]: action.value };
  },
  SET_PORTAL_QUESTIONS_PENDING: state => {
    state.getQuestions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  SET_PORTAL_QUESTIONS_FULFILLED: (state, action) => {
    state.setQuestions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.setQuestions.data = action.payload.data;
  },
  SET_PORTAL_QUESTIONS_REJECTED: (state, action) => {
    state.setQuestions.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.setQuestions.data = action.payload.data;
  },
});
export default portalQuestionsReducer;
