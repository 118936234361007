import axios from 'axios';
import * as _ from 'lodash';
import { getMicroServiceUrl, getConfigUrl, getVariable } from '../helpers';
import store from 'store2';
import history from '../history';

export const locationConfig = postcode => ({
  type: 'GET_LOCATION_CONFIG',
  payload: axios.get(`${getMicroServiceUrl('location')}gwf_location/${postcode}`),
});

export const setComponentWidth = appWidth => ({
  type: 'SET_COMPONENT_WIDTH',
  appWidth,
});

export const locationError = msg => ({
  type: 'LOCATION_ERROR',
  msg,
});

export const getPage = (endpoint, microservice = 'portal') => ({
  type: `GET_PAGE_${endpoint.toUpperCase()}`,
  payload: axios.get(`${getMicroServiceUrl(microservice)}${endpoint}`),
});

export const getLocationQA = (postcode, setSubmitting) => {
  return dispatch => {
    const response = dispatch({
      type: 'GET_LOCATION',
      payload: axios.get(`${getMicroServiceUrl('location')}gwf_location/${postcode.replace(/\s+/g, '')}`),
      meta: {
        postcode,
      },
    });

    // clear before call
    store('swsmLocation', {
      postcode_store: null,
    });

    response
      .then(res => {
        const responseWaterClient = _.get(res, 'action.payload.data.data.water_client');

        //remove this ****
        const localWaterClient = 'www';
        const postcode_store = _.get(res, 'action.meta.postcode');
        // const waterClient = _.get(res, 'action.payload.data.data.water_client');

        if (
          ((localWaterClient === 'www' || localWaterClient === 'aus' || localWaterClient === 'usa') && postcode) ||
          (responseWaterClient === localWaterClient && postcode)
        ) {
          store('swsmLocation', {
            postcode_store,
          });

          const redirect = _.get(res, 'action.payload.data.data.is_redirect_require');
          const redirect_url = _.get(res, 'action.payload.data.data.site_url');
          const postcode = _.get(res, 'action.payload.data.data.postcode');
          const australia = getVariable('client', 'waterClient', 'aus');

          const swsmInternal = getVariable('mode', 'swsm', false);
          const swsmRedirect = getVariable('mode', 'integrationClient');

          if (swsmInternal && australia !== 'usa') {
            window._paq.push(['setCustomDimension', 1, responseWaterClient]);
          }

          if (redirect === true && !swsmInternal && swsmRedirect !== 'ccw') {
            if (redirect_url !== '' && window.location.origin !== redirect_url) {
              if (redirect_url.includes('getwaterfit.co.uk')) {
                return setTimeout(() => {
                  window.location.replace(redirect_url + `/#/?postcode=${postcode}`);
                }, 3000);
              }
              return setTimeout(() => {
                window.location.replace(redirect_url);
              }, 3000);
            }
          }
          else {
            history.push('/questions');
          }
        }
        else {
          setSubmitting(false);
          dispatch(locationError('This postcode is not supported by current provider'));
        }
      })
      .catch(err => {
        setSubmitting(false);
      });
  };
};

export const getConfig = () => ({
  type: 'GET_CONFIG',
  payload: axios.get(`${getConfigUrl()}`),
});

export const toggleRewardsProgram = isVisible => ({
  type: 'DEBUG_TOGGLE_REWARDS_PROGRAM',
  isVisible,
});

export const toggleTutorial = isVisible => ({
  type: 'DEBUG_TOGGLE_TUTORIAL',
  isVisible,
});

export const postReviewData = (data, onSuccess, onFailed, componentId) => {
  return dispatch => {
    const response = dispatch({
      type: 'POST_REVIEW_DATA',
      payload: axios.post(`${getMicroServiceUrl('user-content')}review`, data),
    });

    response
      .then(() => {
        onSuccess();
        if (data.product === 'portal') {
          dispatch({
            type: 'HIDE_PORTAL_REVIEW',
            componentId,
          });
        }
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const clearFilters = () => ({
  type: 'CLEAR_FILTERS',
});
