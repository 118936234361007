import React from 'react';
import ContactFormUk from './ContactFormUK';
import { getVariable } from '../../helpers';
import ContactFormOther from './ContactFormOther';

interface ContactFormProps {
  sendContactUs: Function;
  setContactUsEmail: Function;
  contactForm: any;
}

const ContactForm: React.FC<ContactFormProps> = props => {
  const { sendContactUs, setContactUsEmail, contactForm } = props;
  const swsmCountry = getVariable('client', 'waterClient');

  return (
    <React.Fragment>
      {swsmCountry === 'www' ? (
        <ContactFormUk setContact={sendContactUs} data={contactForm} />
      ) : (
        <ContactFormOther setContact={setContactUsEmail} data={contactForm} />
      )}
    </React.Fragment>
  );
};

export default ContactForm;
