import styled from 'styled-components';
import { colors } from '../../../assets/variables';
import { Col } from 'react-styled-flexboxgrid';

const Wrap = styled.div`
  display: block;
  margin-bottom: 32px;
  font-family: 'Open sans', sans-serif;
`;
const ToggleWrap = styled.div`
  height: 38px;
  width: 160px;
  border: 2px solid ${colors.primary};
  border-radius: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
`;
const Item = styled.div`
  padding: 8px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid ${colors.primary};
  &:last-child {
    border-right: 0px;
  }
  &.active {
    background: ${colors.primary};
    color: white;
  }
  &.inactive {
    color: ${colors.primary};
    background: white;
  }
`;
const Column = styled(Col)`
  margin-bottom: 32px;

  @media screen and (max-width: 700px) {
    padding: 0;
  }
`;

export default { Wrap, Column, ToggleWrap, Item };
