import { CompetitionReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: CompetitionReducer = {
  getCompetitions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  postCompetition: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  lastWinner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const competitionsReducer = createReducer(initialState, {
  GET_COMPETITIONS_PENDING: state => {
    state.getCompetitions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_COMPETITIONS_FULFILLED: (state, action) => {
    state.getCompetitions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getCompetitions.data = action.payload.data;
  },

  GET_COMPETITIONS_REJECTED: (state, action) => {
    state.getCompetitions.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.getCompetitions.data = null;
  },
  ENTER_COMPETITION_PENDING: state => {
    state.postCompetition.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  ENTER_COMPETITION_FULFILLED: (state, action) => {
    state.postCompetition.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.postCompetition.data = action.payload.data;
  },

  ENTER_COMPETITION_REJECTED: (state, action) => {
    state.postCompetition.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.postCompetition.data = null;
  },
  GET_LAST_WINNER_PENDING: state => {
    state.lastWinner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_LAST_WINNER_FULFILLED: (state, action) => {
    state.lastWinner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.lastWinner.data = action.payload.data;
  },

  GET_LAST_WINNER_REJECTED: (state, action) => {
    state.lastWinner.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.lastWinner.data = null;
  },
});

export default competitionsReducer;
