import React from 'react';
import Radio from '../../Radio';
import Styled from './Radio.style';
import { useTranslation } from 'react-i18next';
interface RadioControlsProps {
  answerKey: string;
  options: Array<any>;
  key: string;
  updateAnswerKey: Function;
  value: string;
}
const RadioControls: React.FC<RadioControlsProps> = props => {
  const { options, updateAnswerKey = () => void 0, value, answerKey } = props;
  const [t] = useTranslation('common');
  const handleAnswer = value => {
    updateAnswerKey(answerKey, value);
  };
  const renderOptions = () => {
    return (
      options &&
      options.map((el, index) => {
        return (
          <Radio
            key={answerKey + '--' + el.value}
            id={answerKey + '--' + el.value}
            name={answerKey + '--' + el.value}
            onChange={() => handleAnswer(el.value)}
            aria-label={el.value}
            title={t(el.text)}
            checked={value === el.value}
            value={el.value}
          />
        );
      })
    );
  };
  return (
    <Styled.Wrap style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
      {renderOptions()}
    </Styled.Wrap>
  );
};
export default RadioControls;
