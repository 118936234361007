import styled, { css } from 'styled-components';
import { colors } from './../../assets/variables';
import { Select } from '@savewatersavemoney/swsm-ui';

const disabledStyles = css`
  background-color: ${colors.disabled};
  color: ${colors.darkGray};
  cursor: not-allowed;
  box-shadow: none;

  :hover,
  :focus {
    box-shadow: none;
    background-color: ${colors.disabled};
    color: ${colors.darkGray};
  }
`;

const InputWrapper = styled.div`
  max-width: 400px;
  position: relative;
  display: block;
  margin-top: 30px;
  z-index: 10;
  button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 14px 20px;
  }
`;

const Error = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
`;

const Input = styled.input`
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
  display: block;
  font-size: 18px;
  color: #4a4a4a;
  padding: 1em;
  border: 0 solid transparent;
  width: 100%;
  background-color: #fff;
  font-weight: 400;
  border: 1px solid ${colors.primary};
  line-height: inherit;
  min-height: 66px;
  letter-spacing: 1px;
  border-radius: 5px;
`;

const ButtonInput = styled.button`
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-size: 16px;
  color: #fff;
  background-color: ${colors.primary};
  font-family: inherit;
  line-height: 1.15;
  position: relative;
  padding: 16px 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  display: inline-block;
  :hover,
  :focus {
    color: rgb(255, 255, 255);
    background-color: rgba(11, 38, 65, 0.8);
  }
  ${props => (props.loading === 'true' ? disabledStyles : '')}
`;
const Dropdown = styled(Select)`
  border: 2px solid black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 66px;
  max-width: 325px;
  padding: 16px;
  background-color: white;
`;
const DropdownWrap = styled.div`
  &:after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid black;
    top: 55%;
  }
`;
const desktopStyles = css`
  ${Input} {
    font-size: 1.5em;
  }
`;

export default {
  Error,
  InputWrapper,
  Input,
  ButtonInput,
  Dropdown,
  DropdownWrap,
};
