import styled from 'styled-components';
import { colors } from '../../../assets/variables';
const Wrap = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;
  gap: 8px;
  & > label {
    border: 2px solid ${colors.primary};
    border-radius: 10px;
    background: none;
    margin-bottom: 0px;
    height: 58px;
    max-height: 58px;
    padding: 0px 30px;
    & > div {
      margin-top: 0px !important;
      margin-left: 22px !important;
      & > div {
        font-size: 16px;
        line-height: 1em;
      }
    }
  }
  &:first-child > img + label {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child > img + label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
export default { Wrap };
