import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background-image: none;
  min-height: 780px;
  ${props => {
    if (props.homepage) {
      return homepageStyles;
    }
  }};
`;

const homepageStyles = css`
  min-height: 700px;
  background: transparent;
  align-items: center;
`;

export default { Wrapper };
