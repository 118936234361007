import React from 'react';
import { Button, Input, Icon } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Styled from '../ContactForm.style';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../index';
import Reaptcha from 'reaptcha';

interface ContactFormUkProps {
  setContact: Function;
  data: any;
}

const ContactFormUk: React.FC<ContactFormUkProps> = props => {
  const { data, setContact } = props;
  const [t] = useTranslation('common');
  const skipRecaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true';
  const CommunicationSchema = Yup.object().shape({
    waterCompany: Yup.string().required(t('portal.general_form_errors.required')),
    name: Yup.string(),
    phoneNumber: Yup.number().typeError(t('portal.general_form_errors.phone_number_invalid')),
    email: Yup.string()
      .email(t('portal.general_form_errors.email_required'))
      .required(t('portal.general_form_errors.required')),
    message: Yup.string().required(t('portal.general_form_errors.required')),
    recaptchaResponse: Yup.string().required('reCAPTCHA is required'),
  });
  const thankYouText = t('portal.customer_service.ticket_reference_thankyou');
  const sumbitThankyou = () => {
    return (
      <Styled.Wrap style={{ margin: 'auto' }}>
        <Icon icon="checkRounded" size={100} style={{ margin: 'auto', width: '100%' }} />
        <Typography style={{ paddingTop: 30, textAlign: 'center' }} size="p" text={thankYouText} />
      </Styled.Wrap>
    );
  };

  const text_translation = {
    customer_service: {
      ask_me_button_text: t('portal.customer_service.ask_me_button_text'),
      ask_me_form_subject: t('portal.customer_service.ask_me_subject'),
      ask_me_form_title: t('portal.customer_service.ask_me_form_title'),
      required: t('portal.customer_service.required'),
      form_elements: {
        contact_number: t('portal.customer_service.form_elements.contact_number'),
        email: t('portal.customer_service.form_elements.email'),
        name: t('portal.customer_service.form_elements.name'),
        order_number: t('portal.customer_service.form_elements.order_number'),
        query: t('portal.customer_service.form_elements.query'),
        query_about: t('portal.customer_service.form_elements.query_about'),
        query_freetext: t('portal.customer_service.form_elements.query_freetext'),
        water_company: t('portal.customer_service.form_elements.water_company'),
      },
      live_query: t('portal.customer_service.live_query'),
      waterbutt_form_query: t('portal.customer_service.waterbutt_form_query'),
      waterbutt_form_title: t('portal.customer_service.waterbutt_form_title'),
    },
    options: {
      missing_product: t('portal.customer_service.missing_product'),
      fault_in_product: t('portal.customer_service.fault_in_product'),
      missing_part: t('portal.customer_service.missing_part'),
      postcode_entry: t('portal.customer_service.postcode_entry'),
      other: t('portal.customer_service.other'),
    },
  };

  return (
    <React.Fragment>
      <Row>
        {data.setContactEmail.status.done ? (
          sumbitThankyou()
        ) : (
          <Formik
            initialValues={{
              waterCompany: 'twc',
              name: '',
              phoneNumber: '',
              email: '',
              message: '',
              recaptchaResponse: skipRecaptcha ? 'test123' : '',
            }}
            validationSchema={CommunicationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setContact(
                values,
                () => setSubmitting(false),
                () => setSubmitting(false),
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Styled.Wrap>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      label={`${text_translation.customer_service.form_elements.name}`}
                      type="text"
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name ? errors.name : undefined}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Input
                      label={`${text_translation.customer_service.form_elements.contact_number}`}
                      type="text"
                      id="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : undefined}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={`${text_translation.customer_service.form_elements.email}`}
                      type="email"
                      id="email"
                      value={values.email}
                      error={errors.email && touched.email ? errors.email : undefined}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Styled.Textarea
                      style={{ resize: 'none', width: '100%' }}
                      rows={5}
                      cols={49}
                      id="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={text_translation.customer_service.form_elements.query_freetext}
                    ></Styled.Textarea>
                  </Col>

                  {skipRecaptcha ? null : (
                    <Col xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
                      <Reaptcha
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                        onVerify={response => setFieldValue('recaptchaResponse', response)}
                        onExpire={() => setFieldValue('recaptchaResponse', '')}
                      />
                      {errors.recaptchaResponse && touched.recaptchaResponse && (
                        <Styled.ErrorInline>{errors.recaptchaResponse}</Styled.ErrorInline>
                      )}
                    </Col>
                  )}
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '25px',
                    }}
                  >
                    <Button disabled={isSubmitting} type="submit">
                      {t('portal.forgot_password.submit_button')}
                    </Button>
                  </Col>
                </Styled.Wrap>
              </form>
            )}
          </Formik>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ContactFormUk;
