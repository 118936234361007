import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #d7f1ff;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 32px;
  min-height: 240px;
`;
const Rating = styled.div`
  display: flex;
  max-width: 250px;
  margin: 8px 0;
`;

const Star = styled.div`
  cursor: pointer;
  padding: 0 5px;
  flex: 1;
`;
const Error = styled.div`
  color: red;
  font-size: 0.8em;
  margin-bottom: 16px;
`;

const ReviewBox = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 90px;
  resize: none;
  padding: 10px 16px 24px 16px;
  border: 1px solid rgba(11, 38, 65, 1);
  border-radius: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
`;
const CtaWrap = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export default {
  Wrap,
  Star,
  Rating,
  ReviewBox,
  CtaWrap,
  Error,
};
