import React, { useEffect } from 'react';
import ExternalLinks from '../../ExternalLinks';
import PageOverviewBanners from './PageOverviewBanners';
import PageOverviewBonusChallenges from './PageOverviewBonusChallenges';
import { Loader } from '@savewatersavemoney/swsm-ui';
import { BasePage } from '../../../interfaces';
import { FooterInfo } from './../../index';
import RewardsBanner from '../../RewardsBanner';
import UnifiedLabel from '../../UnifiedLabel';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import CompetitionBanner from '../../CompetitionBanner';

import { getVariable } from '../../../helpers';

interface PageOverviewProps extends Omit<BasePage, 'page'> {
  challenges: any;
  clearFilters: Function;
  bonusChallenges: Object;
  getBonusChallenges: Function;
  generalData: any;
  getUnifiedLabel: Function;
  unifiedLabel: any;
  competitions: any;
  listCompetitions: Function;
}
const PageOverview: React.FC<PageOverviewProps> = props => {
  const {
    appWidth,
    getBonusChallenges,
    clearFilters,
    challenges,
    bonusChallenges,
    generalData,
    getUnifiedLabel,
    unifiedLabel,
    listCompetitions,
    competitions,
  } = props;
  const isDesktop = appWidth > 800;
  const competitionDraw = _.get(props, 'app.clientConfig.data.data.competitionDrawEnabled');
  useEffect(() => {
    if (!challenges.bonusChallengesStatus.done && !challenges.bonusChallengesStatus.loading) {
      getBonusChallenges();
    }
    if (competitionDraw) {
      listCompetitions();
    }
    getUnifiedLabel();
  }, [
    getBonusChallenges,
    clearFilters,
    challenges.bonusChallengesStatus,
    getUnifiedLabel,
    competitionDraw,
    listCompetitions,
  ]);
  const uwlEnabled = _.get(props, 'app.clientConfig.data.data.uwlaEnabled');
  const swsmInternal = getVariable('mode', 'swsm', false);
  const marketplaceEnabled = _.get(props, 'app.clientConfig.data.data.is_market_place_available');
  const videoLibrary = _.get(props, 'app.clientConfig.data.data.ask_me_videos_enabled');
  const clientSLug = _.get(props, 'app.clientConfig.data.data.water_client');
  const reviewEnabled = _.get(props, 'app.clientConfig.data.data.reviewEnabled');
  const defaultLanguage = _.get(props, 'app.clientConfig.data.data.defaultLanguage');

  if (generalData.status.loading || challenges.bonusChallengesStatus.loading) {
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <PageOverviewBonusChallenges
        swsmInternal={swsmInternal}
        clientSlug={clientSLug}
        isDesktop={isDesktop}
        challenges={challenges}
        bonusChallenges={bonusChallenges}
      />
      <RewardsBanner />
      <Row>
        {uwlEnabled && (
          <Col md={6} sm={12} xs={12}>
            <UnifiedLabel data={unifiedLabel} defaultLang={defaultLanguage} />
          </Col>
        )}
        {competitionDraw && (
          <Col md={6} xs={12} sm={12}>
            <CompetitionBanner isDesktop={isDesktop} competitions={competitions} />
          </Col>
        )}
      </Row>

      <PageOverviewBanners
        swsmInternal={swsmInternal}
        clientSlug={clientSLug}
        isDesktop={isDesktop}
        marketplaceBanner={marketplaceEnabled}
        videoLibrary={videoLibrary}
        reviewEnabled={reviewEnabled}
      />

      <ExternalLinks />
      {clientSLug === 'nrwa' || clientSLug === 'awe' ? null : <FooterInfo slug={clientSLug} />}
    </>
  );
};
export default PageOverview;
