import styled from 'styled-components';

const Wraper = styled.div`
  max-width: 375px;
  margin: auto;
`;

const Row = styled.div`
  margin-bottom: 4px;

  display: flex;
  align-items: flex-end;
  & > label {
    margin-bottom: 0px;
  }
  &:first-child > label {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child > label {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  & > img + label {
    min-width: 280px;
    @media screen and (max-width: 414px) {
      min-width: 200px;
    }
  }
  &:first-child > img + label {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child > img + label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Image = styled.img`
  margin-top: 20px;
  height: 80px;
  width: 80px;
`;

export default {
  Wraper,
  Row,
  Image,
};
