/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Styled from './Review.style';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@savewatersavemoney/swsm-ui';
import Typography from '../Typography';
import * as _ from 'lodash';
interface ReviewProps {
  review: any;
  viewReview: Function;
  setReview: Function;
}

const Review: React.FC<ReviewProps> = props => {
  const { setReview, review, viewReview } = props;
  const hasLeftReview = _.get(review, 'getReview.data.success');
  const { done } = _.get(review, 'setReview.status');
  console.log(hasLeftReview);
  React.useEffect(() => {
    viewReview();
  }, [viewReview]);
  // const { laoding, error, done } = review.status;
  const OrderSchema = Yup.object().shape({
    body: Yup.string().max(200, 'Comments shold be less than 200 characters'),
    rating: Yup.number()
      .min(1, 'Rating is required')
      .required('Rating is required'),
  });
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const handleMouseEnter = value => {
    setHoverRating(value);
  };
  const handleMouseLeave = () => {
    setHoverRating(0);
  };
  const GenerateStars = props => {
    const { onChange } = props;

    const handleClick = value => {
      setRating(value);
      onChange(value);
    };

    return (
      <Styled.Rating>
        {[1, 2, 3, 4, 5].map(value => {
          return (
            <Styled.Star
              onClick={() => {
                handleClick(value);
              }}
              onMouseEnter={() => handleMouseEnter(value)}
              onMouseLeave={handleMouseLeave}
              key={value}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
                <path
                  fill={value <= (rating || hoverRating) ? '#25b3eb' : '#fff'}
                  fillRule="evenodd"
                  stroke="#0B2641"
                  strokeOpacity="1"
                  d="M29.504 33.457l-2.006-11.696 8.498-8.283-11.744-1.707L19 1.13 13.748 11.77 2.004 13.478l8.498 8.283-2.006 11.696L19 27.935l10.504 5.522z"
                />
              </svg>
            </Styled.Star>
          );
        })}
      </Styled.Rating>
    );
  };
  if (done) {
    return (
      <Styled.Wrap>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            margin: 'auto',
            padding: '0px',
            flexDirection: 'column',
          }}
        >
          <Typography size="3" text="Thank you for your review" />
          <Typography size="p" text="We will get in touch with you as soon as possible" />
        </div>
      </Styled.Wrap>
    );
  }
  if (hasLeftReview) {
    return (
      <Styled.Wrap>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            margin: 'auto',
            padding: '0px',
            flexDirection: 'column',
          }}
        >
          <Typography size="3" text="Thank you" />
          <Typography size="p" text="You already left a review" />
        </div>
      </Styled.Wrap>
    );
  }
  return (
    <Styled.Wrap>
      <Typography size="3" text="Leave a review" style={{ textAlign: 'left' }} />
      <Formik
        initialValues={{ rating: 5, body: '', product: 'GetWaterFit' }}
        validationSchema={OrderSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setReview(values);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <GenerateStars
              handleBlur={handleBlur}
              value={rating}
              onChange={rating => setFieldValue('rating', rating)}
              name="rating"
              id="rating"
            />

            <div style={{ position: 'relative' }}>
              <Styled.ReviewBox
                handleBlur={handleBlur}
                value={values.body}
                onChange={handleChange}
                name="body"
                id="body"
                maxLength={200}
                rows={4}
                placeholder="Your message..."
              />
              <div
                style={{
                  display: 'flex',
                  fontSize: '12px',
                  position: 'absolute',
                  right: '6px',
                  bottom: '12px',
                  color: values.body.length > 200 ? 'red' : '#0b2641',
                }}
              >
                Maximum allowed characters: {values.body.length}/ 200 characters.
              </div>
            </div>

            <Styled.CtaWrap>
              <div style={{ height: '40px', width: '100%' }}>
                {touched.body && errors.body && <Styled.Error>{errors.body}</Styled.Error>}
                {touched.rating && errors.rating && <Styled.Error>{errors.rating}</Styled.Error>}
              </div>
              <Button
                disabled={isSubmitting}
                type="submit"
                style={{ marginTop: '8px', width: '200px', minWidth: '200px' }}
                full
              >
                Submit Review
              </Button>
            </Styled.CtaWrap>
          </form>
        )}
      </Formik>
    </Styled.Wrap>
  );
};
export default Review;
