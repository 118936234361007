import { Button } from '@savewatersavemoney/swsm-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '../Radio/Radio';
import SliderControl from '../Slider/Slider';

interface ControlsProps {
  data: any;
  updatePortalQuestions: Function;
  sendPortalQuestions: Function;
  answers: any;
  portalQuestions: any;
}
const Controls: React.FC<ControlsProps> = props => {
  const { data, updatePortalQuestions, sendPortalQuestions, answers } = props;
  // const { loading, error } = _.get(portalQuestions, 'setQuestions.status');
  const [t] = useTranslation('common');
  const renderQuestion = () => {
    return (
      data &&
      data.map((el, index) => {
        return (
          <div key={index}>
            <div style={{ color: '#0b2641', marginBottom: '16px' }}>{t(el.text)}</div>
            {el.controls.map((control, index) => {
              const { type, key, options, defaultValue, max, min } = control;
              switch (type) {
                case 'buttons':
                  return (
                    <Radio
                      updateAnswerKey={updatePortalQuestions}
                      options={options}
                      value={answers[key]}
                      key={key}
                      answerKey={key}
                    />
                  );
                case 'slider':
                  return (
                    <SliderControl
                      key={key}
                      value={answers[key]}
                      updateAnswerKey={updatePortalQuestions}
                      answerKey={key}
                      defaultValue={defaultValue}
                      max={max}
                      min={min}
                    />
                  );
                default:
                  return <div key={key}>Controls for {type} does not exist</div>;
              }
            })}
          </div>
        );
      })
    );
  };
  // console.log(loading);
  // const renderError = () => {
  //   if (error) {
  //     return <div>There has been an error</div>;
  //   }
  // };

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontWeight: 700,
      }}
    >
      {renderQuestion()}
      <Button
        full
        onClick={() =>
          sendPortalQuestions(
            answers,
            () => {
              console.log('success');
            },
            () => {
              console.log('failed');
            },
          )
        }
      >
        Update outdoor water use
      </Button>
    </div>
  );
};
export default Controls;
