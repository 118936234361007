import React from 'react';
import Styled from './CompetitionBanner.style';
import { renderCdnUrl } from '../../helpers';
import * as _ from 'lodash';
import history from '../../history';
import { Icon } from '@savewatersavemoney/swsm-ui';
interface CompetitionBannerProps {
  competitions: any;
  isDesktop: boolean;
}

const CompetitionBanner: React.FC<CompetitionBannerProps> = props => {
  const { competitions, isDesktop } = props;

  const listCompetitions = _.get(competitions, 'getCompetitions.data');
  const renderFeatured = listCompetitions && listCompetitions.find(item => item.isFeatured === true);

  const image = renderFeatured && renderFeatured.imageUrl;

  const url = renderCdnUrl(image);

  return (
    <Styled.Wrap
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: isDesktop ? '50px 0px' : '-140px',
        backgroundSize: isDesktop ? 'contain' : 'cover',
      }}
    >
      <Styled.Content>
        <p style={{ fontSize: isDesktop ? '16px' : '14px', margin: '0 0 24px 0' }}>
          Get more water-efficient with this month’s prize!
        </p>
        <p style={{ fontSize: '48px', fontStyle: 'italic', fontFamily: 'DM Serif Display', margin: '0 0 16px 0' }}>
          Win it!
        </p>
        <p style={{ fontSize: isDesktop ? '16px' : '14px', margin: '0 0 24px 0' }}>A Caroma toilet</p>
        {renderFeatured && renderFeatured.hasEntryMade ? (
          <p
            style={{
              fontSize: isDesktop ? '16px' : '14px',
              display: 'flex',
              alignItems: 'center',
              margin: 0,
            }}
          >
            You entered this competition!
          </p>
        ) : (
          <p
            onClick={() => history.push(`/competitions/${renderFeatured && renderFeatured.id}`)}
            style={{
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            <Icon icon="chevronRight" size={10} colorPrimary="#21AAE8" style={{ marginRight: '8px' }} />
            Enter NOW!
          </p>
        )}
      </Styled.Content>
    </Styled.Wrap>
  );
};

export default CompetitionBanner;
