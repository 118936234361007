import React from 'react';
import Slider from 'rc-slider';
import Styled from './Slider.style';
interface SliderProps {
  answerKey: string;
  defaultValue: string;
  key: string;
  max: string | number;
  min: number;
  updateAnswerKey: Function;
  value: string;
}
class SliderControl extends React.Component<SliderProps, any> {
  constructor(props) {
    super(props);
    const currentValue = props.value != null ? props.value : parseInt(props.defaultValue, 10);
    this.state = {
      localValue: currentValue,
    };
  }
  updateDebounced = val => {
    const { answerKey, updateAnswerKey = () => void 0 } = this.props;
    updateAnswerKey(answerKey, val);
  };
  render() {
    const { min, max } = this.props;
    const maxValue = max.toString();
    const onSliderChange = value => {
      this.setState({ localValue: value });
      this.updateDebounced(value);
    };
    return (
      <Styled.Wraper>
        <Styled.Desc>
          <strong>{this.state.localValue}</strong>
        </Styled.Desc>
        <div id={'2'} style={{ padding: '10px' }}>
          <Slider
            ariaLabelForHandle="Slider handle"
            min={min}
            max={Number(maxValue)}
            value={this.state.localValue}
            onChange={onSliderChange}
          />
        </div>
      </Styled.Wraper>
    );
  }
}
export default SliderControl;
