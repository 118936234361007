import React from 'react';
import { renderParameter, getVariable } from './../../helpers';
import { useFormik } from 'formik';
import Styled from './PostcodeForm.style';
import * as Yup from 'yup';
import { Icon, ButtonLoading } from '@savewatersavemoney/swsm-ui';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-styled-flexboxgrid';

const PostcodeSchema = Yup.object().shape({
  postcode: Yup.string().required('Required'),
});

const PostcodeForm: React.FC<any> = props => {
  const { getLocation, userLocation, location } = props;
  const error = _.get(props.userLocation, 'status.error');
  const env = getVariable('mode', 'env');
  const [t] = useTranslation('common');

  const indiaList = [
    { label: t('tata.locality.jpr.text'), value: 'JPR' },
    { label: t('tata.locality.jpab.text'), value: 'JPAB' },
    { label: t('tata.locality.jkb.text'), value: 'JKB' },
    { label: t('tata.locality.ol.text'), value: 'OL' },
  ];

  const usaList = [
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Connecticut', value: 'Connecticut' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'New Hampshire', value: 'Hampshire' },
    { label: 'New Jersey', value: 'Jersey' },
    { label: 'New Mexico', value: 'Mexico' },
    { label: 'New York', value: 'York' },
    { label: 'North Carolina', value: 'NorthCarolina' },
    { label: 'North Dakota', value: 'Dakota' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Pennsylvania', value: 'Pennsylvania' },
    { label: 'Rhode Island', value: 'RhodeIsland' },
    { label: 'South Carolina', value: 'SouthCarolina' },
    { label: 'South Dakota', value: 'SouthDakota' },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    { label: 'West Virginia', value: 'WestVirginia' },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' },
  ];

  React.useEffect(() => {
    if (renderParameter('postcode')) {
      if (document.readyState === 'complete' && !userLocation.done && !userLocation.loading) {
        getLocation(renderParameter('postcode'), () => void 0);
      }
    }
  }, [getLocation, userLocation.loading, userLocation.done]);
  const formik = useFormik({
    initialValues: {
      postcode: renderParameter('postcode') ? renderParameter('postcode') : '',
    },
    validationSchema: PostcodeSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (location === '/usa') {
        getLocation('awe', setSubmitting);
      }
      else
        getLocation(values.postcode, setSubmitting);
    },
  });

  const handleSelect = e => {
    if (e.currentTarget !== undefined) {
      formik.setFieldValue('postcode', e.currentTarget.value);
    }
  };
  const handleSelectUsa = e => {
    if (e.currentTarget !== undefined) {
      formik.setFieldValue('postcode', e.currentTarget.value);
    }
  };

  return (
    <form id={props.subsection ? 'postcodeForm' : 'postcodeFormMain'} onSubmit={formik.handleSubmit}>
      {location === '/india' && (
        <Styled.DropdownWrap>
          <Styled.Dropdown
            placeholder={t('portal.homepage.select_placeholder')}
            options={indiaList}
            onChange={e => handleSelect(e)}
            value={formik.values.postcode}
          />
        </Styled.DropdownWrap>
      )}
      {location === '/usa' && (
        <Styled.DropdownWrap>
          <Styled.Dropdown
            placeholder="Select your state"
            options={usaList}
            onChange={e => handleSelectUsa(e)}
            value={formik.values.postcode}
          />
        </Styled.DropdownWrap>
      )}
      {location === '/australia' && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Col xs={12}>
            <ButtonLoading
              loaderColor="#062640"
              aria-label="Submit postcode"
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              type="submit"
              onClick={e => {
                if (e.currentTarget !== undefined) {
                  formik.setFieldValue('postcode', 'AUSTRALIA');
                }
              }}
              style={{
                position: 'relative',
                marginBottom: 10,
                borderRadius: 8,
                textAlign: 'center',
                width: '100%',
                background: '#00a3da',
              }}
            >
              Moreton Bay
            </ButtonLoading>
          </Col>
          <Col xs={12}>
            <ButtonLoading
              loaderColor="#062640"
              aria-label="Submit postcode"
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              type="submit"
              onClick={e => {
                if (e.currentTarget !== undefined) {
                  formik.setFieldValue('postcode', 'AUSTRALIA');
                }
              }}
              style={{
                position: 'relative',
                marginBottom: 10,
                borderRadius: 8,
                textAlign: 'center',
                width: '100%',
                background: '#00a3da',
              }}
            >
              Sunshine Coast
            </ButtonLoading>
          </Col>
          <Col xs={12}>
            <ButtonLoading
              loaderColor="#062640"
              aria-label="Submit postcode"
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
              type="submit"
              onClick={e => {
                if (e.currentTarget !== undefined) {
                  formik.setFieldValue('postcode', 'AUSTRALIA');
                }
              }}
              style={{
                position: 'relative',
                marginBottom: 10,
                borderRadius: 8,
                textAlign: 'center',
                width: '100%',
                background: '#00a3da',
              }}
            >
              Noosa
            </ButtonLoading>
          </Col>
        </div>
      )}
      {location === undefined && (
        <Styled.Input
          type="text"
          name="postcode"
          aria-label="Enter your postcode"
          placeholder={t('portal.homepage.input_placeholder')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.postcode}
        />
      )}

      {error ? (
        <Styled.Error>
          This {env === 'usa' || env === 'stage-usa' ? 'zip code' : 'postcode'} is not recognised on GetWaterFit,{' '}
          <a href="https://www.getwaterfit.co.uk/#/contact-us"> click here</a> to contact the support team.
        </Styled.Error>
      ) : null}
      {location === '/australia' ? null : (
        <Styled.ButtonInput
          id={props.subsection ? 'postcodeSubmit' : 'postcodeSubmitMain'}
          loaderColor="#062640"
          aria-label="Submit postcode"
          loading={`${formik.isSubmitting}`}
          type="submit"
        >
          <Icon colorPrimary={'#fff'} colorSecondary={'#fff'} icon="arrow" size={36} />
        </Styled.ButtonInput>
      )}
    </form>
  );
};

export default PostcodeForm;
