import { connect } from 'react-redux';
import Controls from './Controls';
import { updatePortalQuestions, sendPortalQuestions } from '../../../actions/index';
// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    answers: state.portalQuestionsReducer.answers,
    portalQuestions: state.portalQuestionsReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updatePortalQuestions: (key, value) => dispatch(updatePortalQuestions(key, value)),
    sendPortalQuestions: (data, onSuccess, onFailed) => dispatch(sendPortalQuestions(data, onSuccess, onFailed)),
  };
};
const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(Controls);
export default QuestionContainer;
