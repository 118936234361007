import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const listCompetitions = () => ({
  type: 'GET_COMPETITIONS',
  payload: axios.get(`${getMicroServiceUrl('competition-draws')}competitions`),
});

export const enterCompetition = (id, data) => {
  return {
    type: 'ENTER_COMPETITION',
    payload: axios.post(`${getMicroServiceUrl('competition-draws')}competitions/enter/${id}`, { data }),
  };
};

export const lastWinner = () => ({
  type: 'GET_LAST_WINNER',
  payload: axios.get(`${getMicroServiceUrl('competition-draws')}competitions/last-winner`),
});
