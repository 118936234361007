import styled, { css } from 'styled-components';
import { colors } from '../../../assets/variables';

const Wrap = styled.div`
  height: auto;
  margin-bottom: 20px;
  display: flex;
  ${props => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const HeroContent = styled.div`
  width: 100%;
  @media screen and (max-width: 769px) and (min-width: 415px) {
    width: 60%;
  }
  ${props => {
    if (props.integration) {
      return integrationStyles;
    }
  }}
`;
const HeroTitle = styled.h1`
  font-size: 46px;
  line-height: 1.3em !important;
  margin-bottom: 30px;
  font-weight: 900;
  color: ${colors.primary};
  margin-bottom: 10px;
  font-family: 'Open sans', sans-serif;
  @media screen and (max-width: 415px) and (min-width: 5000px) {
    padding-right: 160px;
  }
  @media screen and (max-width: 800px) {
    font-size: 26px;
  }
`;
const HeroDescription = styled.h2`
  line-height: 1.5em;
  font-size: 24px;
  font-weight: 400;
  // line-height: 1.5em !important;
  margin-bottom: 30px;
  color: ${colors.primary};
  font-family: 'Open sans', sans-serif;
  // padding-right: 100px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;
const SignInText = styled.h4`
  color: ${colors.primary};

  font-size: 22px;
  font-weight: 400;
  font-family: 'Open sans', sans-serif;
  ${props => {
    if (!props.isDesktop) {
      return css`
        font-size: 18px;
      `;
    }
  }}

  button {
    text-transform: none;
    font-size: 22px;
    font-weight: 700;
    text-decoration: underline;
  }
`;
const InputWrapper = styled.div`
  max-width: 400px;
  position: relative;
  display: block;
  margin-top: 30px;
  z-index: 9;
  button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
  }
`;

const Error = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
`;

const Input = styled.input`
  display: block;
  font-size: 1.25rem;
  color: #4a4a4a;
  padding: 1em;
  border: 0 solid transparent;
  width: 100%;
  background-color: #fff;
  font-weight: 400;
  border: 1px solid ${colors.primary};
  line-height: inherit;
  min-height: 65px;
  letter-spacing: 1px;
  border-radius: 5px;
`;

const ButtonInput = styled.input`
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-size: 16px;
  color: #fff;
  background-color: #0b2641;
  font-family: inherit;
  line-height: 1.15;
  position: relative;
  padding: 16px 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  display: inline-block;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  left: 160px;

  @media screen and (min-width: 730px) and (max-width: 1024px) {
    left: 350px;
    top: 50px;
    width: 75%;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    left: 475px;
    top: 50px;
    width: 75%;
  }

  @media screen and (min-width: 667px) and (max-width: 700px) {
    left: 220px;
    width: 75%;
  }
  @media screen and (min-width: 568px) and (max-width: 666px) {
    left: 200px;
  }
  @media screen and (max-width: 320px) {
    position: absolute;
    left: 130px;
    top: 50px;
  }
`;

const Modal = styled.div`
  transition: all 0.2s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: auto;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  // margin: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 300px;
`;

const ModalTitle = styled.div`
  display: flex;
  font-weight: bolder;
  margin-bottom: 20px;
`;

const ModalText = styled.div`
  display: flex;
  max-width: 440px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
`;

const DownloadWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
  a {
    img {
      min-height: 40px;
      height: 40px;
    }
    &:last-child {
      margin-left: 16px;
    }
  }
`;
const integrationStyles = css`
  justify-content: flex-end;
`;
const desktopStyles = css`
  height: 'auto';
  ${HeroContent} {
    margin-top: 40px;
    width: 'auto';
  }
  ${HeroDescription} {
    line-height: 1.5em;
    font-size: 24px;
  }
  ${Image} {
    position: absolute;
    left: 0;
    top: 150px;
    margin-top: 50px;
    width: 100%;
  }
  ${HeroTitle} {
    font-size: 46px;
  }
`;

export default {
  Wrap,
  Error,
  HeroContent,
  HeroTitle,
  Image,
  HeroDescription,
  InputWrapper,
  Input,
  ButtonInput,
  SignInText,
  Modal,
  ModalContent,
  ModalTitle,
  ModalText,
  DownloadWrap,
};
