import React from 'react';
import { Icon } from '@savewatersavemoney/swsm-ui';
import * as _ from 'lodash';
import Controls from './Controls';
interface BackPanelProps {
  data: any;
}
const BackPanel: React.FC<BackPanelProps> = props => {
  const { data } = props;
  const renderData = _.get(data, 'getQuestions.data');
  const icon = _.get(renderData, 'icon');
  const questions = _.get(renderData, 'questions');
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Icon icon={icon} size={60} />
      <Controls data={questions} />
    </div>
  );
};
export default BackPanel;
