import * as React from 'react';
import CounterControl from './counterControl';
import Styled from './MultiCounterControl.style';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-styled-flexboxgrid';
interface MultiCounterControlProps {
  options: Array<any>;
  updateAnswerKey: Function;
  value: any;
  key: string;
  maxKey: string;
  validateRequiredKeys: Function;
  controls: Array<Object>;
  currentStep: number;
  onTotalUpdate: (total: number) => void;
}
export const MultiCounterControl: React.FC<MultiCounterControlProps> = props => {
  const valueIds = Object.keys(props.value);
  const [t] = useTranslation('common');
  const partialArrayItems = Array.from(props.options, x => x.key);
  const [total, setTotal] = React.useState<number>(0);

  const calculateTotal = React.useCallback(() => {
    return _.reduce(
      partialArrayItems,
      (total, id) => {
        if (_.includes(valueIds, id.toString())) {
          return total + props.value[id];
        }
        return total;
      },
      0,
    );
  }, [partialArrayItems, props.value, valueIds]);
  const sum = calculateTotal();
  React.useEffect(() => {
    if (sum !== total) {
      setTotal(sum);
    }
  }, [calculateTotal, sum, total]);

  React.useEffect(() => {
    props.onTotalUpdate(total);
  }, [props, total]);

  const renderCounters = () => {
    return _.map(props.options, (el, index) => {
      const getMaxValue = props.maxKey !== undefined ? props.maxKey : el.max;

      const disabled = !!(props.maxKey === undefined && typeof el.max === 'object');

      return (
        <Col md={6} xs={6} sm={6} key={`${index}_${el.key}`}>
          <Styled.Item>
            <div className="desc">{t(el.desc)} </div>
            <CounterControl
              answerKey={el.key}
              answers={props.value}
              value={props.value[el.key]}
              max={disabled || !el.max ? 0 : getMaxValue}
              maxInGroup={sum >= getMaxValue}
              min={el.min}
              currentStep={props.currentStep}
              controls={props.controls}
              defaultValue={el.defaultValue}
              updateAnswerKey={props.updateAnswerKey}
              validateRequiredKeys={props.validateRequiredKeys}
            />
          </Styled.Item>
        </Col>
      );
    });
  };

  return (
    <Row key={props.key}>
      {renderCounters()}
      <Col md={12} sm={12} xs={12}>
        <Styled.Total>Total: {sum}%</Styled.Total>
      </Col>
    </Row>
  );
};

export default MultiCounterControl;
