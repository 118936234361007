import React from 'react';
import Styled from './PopUp.style';
import { Row, Grid } from 'react-styled-flexboxgrid';
import { Button, Icon } from '@savewatersavemoney/swsm-ui';
import Typography from '../Typography';
interface PopUpProps {
  isOpen: boolean;
  setPopUpOpen: Function;
  title?: string;
  image?: string;
  description?: string;
  url?: string;
  onButtonClick?: Function;
  registration?: string;
  isDesktop?: any;
}
const PopUp: React.FC<PopUpProps> = props => {
  const {
    isDesktop,
    children,
    isOpen,
    onButtonClick,
    setPopUpOpen,
    title,
    description,
    image,
    url,
    registration,
  } = props;

  return (
    <Styled.Wrap className={isOpen ? 'open' : null} isDesktop={isDesktop}>
      <Grid>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Styled.Close onClick={() => setPopUpOpen(!isOpen)}>
            <Icon icon="times" size={25} colorPrimary="#fff" />
          </Styled.Close>
        </Row>
        {children ? null : (
          <Row>
            <Styled.Container>
              <Typography size="2" text={title} />
              <Styled.Image src={image} alt={title} />
              <Typography size="p" text={description} />
              <div>{url}</div>
              <Typography size="p" text={registration} />
              <Button onClick={() => onButtonClick && onButtonClick()}></Button>
            </Styled.Container>
          </Row>
        )}
        <Row>
          <Styled.Children>{children}</Styled.Children>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};
export default PopUp;
