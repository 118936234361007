import React from 'react';
import { Tile } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import history from '../../../history';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../assets/variables';
import { renderCdnUrl, getVariable } from '../../../helpers';

const PageOverviewBanners: React.FC<any> = props => {
  const { isDesktop, videoLibrary } = props;
  const [t] = useTranslation('common');
  const country = getVariable('client', 'waterClient');

  const banners = [
    {
      title: t('portal.home.myuse_banner_title'),
      style: { background: colors.primary },
      theme: 'darkTheme',
      description: t('portal.home.myuse_banner_description'),
      isDesktop,
      bgPosition: isDesktop ? '-130px' : '0',
      onButtonClick: () => {
        history.push('/myuse');
      },
      display: true,
      buttonText: t('portal.home.myuse_banner_buttonText'),
      imageUrl: renderCdnUrl('images/develop/component/my-use-new.webp'),
    },
    {
      title: t('portal.home.easywins_banner_title'),
      style: { background: colors.primary },
      theme: 'darkTheme',
      description: t('portal.home.easywins_banner_descripiton'),
      isDesktop,
      bgPosition: isDesktop ? '-160px' : '0',
      onButtonClick: () => {
        history.push('/easywins');
      },
      display: true,
      buttonText: t('portal.home.easywins_banner_buttonText'),
      imageUrl: renderCdnUrl('images/develop/component/challenges-new.webp'),
    },
    {
      title: t('portal.home.askme_banner_title'),
      style: { background: colors.primary },
      theme: 'darkTheme',
      description: t('portal.home.askme_banner_descripiton'),
      isDesktop,
      bgPosition: '0px',
      onButtonClick: () => {
        history.push('/contact-us');
      },
      display: country === 'www',
      buttonText: t('portal.home.askme_banner_buttonText'),
      imageUrl: renderCdnUrl('images/develop/component/ask-me-new.webp'),
    },
    {
      title: t('portal.customer_service.video_library2'),
      style: { background: colors.primary },
      theme: 'darkTheme',
      description: t('portal.customer_service.video_library_description2'),
      isDesktop,
      bgPosition: isDesktop ? '-140px' : '0',
      onButtonClick: () => {
        history.push('/video-library');
      },
      display: videoLibrary,
      buttonText: t('portal.customer_service.video_library_button'),
      imageUrl: renderCdnUrl('images/develop/component/book-va-new.webp'),
    },
  ];

  return isDesktop ? (
    <Row>
      {banners.map((bannerProps, index) => {
        if (bannerProps.display) {
          return (
            <Col xs={12} sm={12} md={6} key={`key${index}`}>
              <Tile {...bannerProps} />
            </Col>
          );
        }
        return null;
      })}
    </Row>
  ) : (
    <div style={{ display: 'flex', overflowX: 'auto', width: '100%', whiteSpace: 'nowrap', gap: '10px' }}>
      {banners.map((bannerProps, index) => {
        if (bannerProps.display) {
          return (
            <div key={index} style={{ height: 'auto', minWidth: '280px' }}>
              <Tile {...bannerProps} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default PageOverviewBanners;
