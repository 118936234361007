import { connect } from 'react-redux';
import Main from './Main';
import { setComponentWidth, getConfig } from '../../../actions';
import { AppState } from '../../../interfaces';
import {
  getTotalRegistration,
  getTotalPotentialCarbonSaving,
  getTotalPotentialMonetarySaving,
  getTotalPotentialWaterSaving,
} from '../../../actions/reportingFigures';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    app: state.appReducer,
    appWidth: state.uiReducer.appWidth,
    user: state.userReducer,
    userLocation: state.locationReducer,
    reporting: state.reportingFiguresReducer,
  };
};
const mapDispatchToProps = dispatch => ({
  setComponentWidth: appWidth => dispatch(setComponentWidth(appWidth)),
  getConfig: () => dispatch(getConfig()),
  getTotalRegistration: () => dispatch(getTotalRegistration()),
  getTotalPotentialCarbonSaving: () => dispatch(getTotalPotentialCarbonSaving()),
  getTotalPotentialMonetarySaving: () => dispatch(getTotalPotentialMonetarySaving()),
  getTotalPotentialWaterSaving: () => dispatch(getTotalPotentialWaterSaving()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
