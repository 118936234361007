import { getVariable } from '../helpers';

export const breakpoints = {
  tabNav: getVariable('breakpoints', 'tabNav', 900),
};

const swsmInternal = getVariable('client', 'waterClient', 'aus');

export const colorsUk = {
  baseDark: getVariable('colors', 'baseDark', '#575757'),
  baseLight: getVariable('colors', 'baseLight', '#FFF'),
  error: getVariable('colors', 'error', '#FF4136'),
  success: getVariable('colors', 'success', '#56BB00'),
  disabled: getVariable('colors', 'disabled', '#D8D8D8'),
  primary: getVariable('colors', 'primary', '#0b2641'),
  secondary: getVariable('colors', 'primary', '#0b2641'),
  tertiary: getVariable('colors', 'tertiary', '#d7f1ff'),
  green: getVariable('colors', 'green', '#86B049'),
  lightGreen: getVariable('colors', 'lightGreen', '#e2edd9'),
  action: getVariable('colors', 'action', '#25b3eb'),
  lightGray: getVariable('colors', 'lightGray', '#E0E0E0'),
  darkGray: getVariable('colors', 'darkGray', '#595959'),
  water: getVariable('colors', 'water', '#25b3eb'),
  energy: getVariable('colors', 'energy', '#F4AC05'),
  money: getVariable('colors', 'money', '#54A93E'),
  t1: getVariable('colors', 't1', '#e95b7c'),
  t2: getVariable('colors', 't2', '#9d68a7'),
  t3: getVariable('colors', 't3', '#f3a536'),
  t4: getVariable('colors', 't4', '#1d7fcb'),
  t5: getVariable('colors', 't5', '#21c4c5'),
  t6: getVariable('colors', 't6', '#8ed131'),
  t7: getVariable('colors', 't7', '#8c1231'),
  c1: getVariable('themes', 'c1', '#0F3E5D'),
  c2: getVariable('themes', 'c2', '#174489'),
  c3: getVariable('themes', 'c3', '#1A5A9D'),
  c4: getVariable('themes', 'c4', '#1F87C4'),
  c5: getVariable('themes', 'c5', '#25B3EB'),
  c6: getVariable('themes', 'c6', '#7ED2F5'),
};

export const colorsAustralia = {
  baseDark: getVariable('colors', 'baseDark', '#575757'),
  baseLight: getVariable('colors', 'baseLight', '#FFF'),
  error: getVariable('colors', 'error', '#FF4136'),
  success: getVariable('colors', 'success', '#56BB00'),
  disabled: getVariable('colors', 'disabled', '#D8D8D8'),
  primary: getVariable('colors', 'primary', '#14558c'),
  secondary: getVariable('colors', 'primary', '#0b2641'),
  tertiary: getVariable('colors', 'tertiary', '#d7f1ff'),
  green: getVariable('colors', 'green', '#86B049'),
  lightGreen: getVariable('colors', 'lightGreen', '#e2edd9'),
  action: getVariable('colors', 'action', '#84c9f3'),
  lightGray: getVariable('colors', 'lightGray', '#E0E0E0'),
  darkGray: getVariable('colors', 'darkGray', '#595959'),
  water: getVariable('colors', 'water', '#25b3eb'),
  energy: getVariable('colors', 'energy', '#F4AC05'),
  money: getVariable('colors', 'money', '#54A93E'),
  t1: getVariable('colors', 't1', '#e95b7c'),
  t2: getVariable('colors', 't2', '#9d68a7'),
  t3: getVariable('colors', 't3', '#f3a536'),
  t4: getVariable('colors', 't4', '#1d7fcb'),
  t5: getVariable('colors', 't5', '#21c4c5'),
  t6: getVariable('colors', 't6', '#8ed131'),
  t7: getVariable('colors', 't7', '#8c1231'),
  c1: getVariable('themes', 'c1', '#14558c'),
  c2: getVariable('themes', 'c2', '#235f95'),
  c3: getVariable('themes', 'c3', '#3c78ad'),
  c4: getVariable('themes', 'c4', '#60a0d0'),
  c5: getVariable('themes', 'c5', '#79bbe7'),
  c6: getVariable('themes', 'c6', '#84c9f3'),
};
export const colors = swsmInternal === 'aus' ? colorsAustralia : colorsUk;

export const measurements = {
  globalRadius: 0,
  checkboxRadius: 0,
  gutter: 30,
  buttonPaddingX: 1.2,
  buttonPaddingY: 1,
  formFieldHeight: getVariable('measurements', 'formFieldHeight', 10),
};

export const QAcolors = {
  baseDark: '#414141',
  baseLight: '#FFF',
  error: '#D20E00',
  success: '#56BB00',
  disabled: '#D8D8D8',
  primary: '#0b2641',
  secondary: '#83389E',
  action: '#25b3eb',
  lightGray: '#E0E0E0',
  darkGray: '#a8a8a8',
  t1: '#00ABB7',
  t2: '#F5B500',
  t3: '#F99B30',
  t4: '#D0021B',
  t5: '#007BC0',
  t6: '#5EB246',
  t7: '#00ABB7',
  c1: '#1AA3D5',
  c2: '#E16934',
  c3: '#61B04C',
  c4: '#864486',
};

export const typography = {
  primaryFont: '\'Open Sans\', sans-serif',
  secondaryFont: '\'Open Sans\', sans-serif',
};

export const range = ['#1683fb', '#1375E1', '#1068C8', '#0D5BAF', '#0A4E96', '#07417D', '#043464', '#02274b'];
