import styled from 'styled-components';
import { Button } from '@savewatersavemoney/swsm-ui';
import { colors } from '../../../assets/variables';

export const IconWrap = styled.div`
  position: absolute;
  right: 15px;
  top: 33px;
`;

export const Error = styled.span`
  color: red;
`;

export const ButtonStyle = styled(Button)`
  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.action};
    color: ${colors.primary};
  }
`;
