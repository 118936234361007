import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  background-color: #eef3fb;
  position: relative;
  margin-bottom: 50px;
  width: ${props => {
    if (!props.isDesktop) {
      return '100%';
    }
    return 'auto';
  }}
  // height: 100%;
  z-index: 9999;
  top: 0;
  padding: 20px;
  // right: -100%;
  transition: all 300ms ease-in-out;
  &.open {
    right: ${props => {
    if (!props.isDesktop) {
      return '0';
    }
    return '0';
  }}
    top: ${props => {
    if (!props.isDesktop) {
      return '0';
    }
    return '20px';
  }}
  }
`;

const Children = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Image = styled.img`
  width: 200px;
  height: auto;
  border: 2px solid ${colors.lightGray};
  margin-bottom: 20px;
`;
const Close = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 20px;
  cursor: pointer;
`;

export default { Wrap, Close, Children, Image, Container };
