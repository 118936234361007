import React from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Styled from './Main.style';
import Counter from '../../../components/Counter';
import { useTranslation } from 'react-i18next';
export interface SubSectionProps {
  isDesktop: any;
  registrations: number;
  water: number;
  carbon: number;
  money: number;
}

const SubSection: React.FC<SubSectionProps> = ({ isDesktop, water, carbon, money, registrations }) => {
  const [t] = useTranslation('common');

  return (
    <Styled.Wrapper isDesktop={isDesktop}>
      <Grid>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <Styled.SubSectionValue>
              {registrations && <Counter type="none" end={registrations} start={0} timer={2} />}
            </Styled.SubSectionValue>

            <p style={{ textAlign: 'center', color: '#0b2641', fontWeight: 600, margin: 0 }}>
              {' '}
              {t('portal.homepage.subsection.users')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Styled.SubSectionValue>
              <Counter type={'money'} end={money} start={0} timer={2} />
            </Styled.SubSectionValue>

            <p style={{ textAlign: 'center', color: '#0b2641', fontWeight: 600, margin: 0 }}>
              {t('portal.homepage.subsection.average')}
            </p>
          </Col>
        </Row>
      </Grid>
    </Styled.Wrapper>
  );
};

export default SubSection;
