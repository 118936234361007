import { ContactFormReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: ContactFormReducer = {
  setContact: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  setContactEmail: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const contactFormReducer = createReducer(initialState, {
  SET_CONTACT_FORM_PENDING: state => {
    state.setContact.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_CONTACT_FORM_FULFILLED: (state, action) => {
    state.setContact.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.setContact.data = action.payload.data;
  },

  SET_CONTACT_FORM_REJECTED: (state, action) => {
    state.setContact.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.setContact.data = null;
  },
  SET_CONTACT_US_EMAIL_PENDING: state => {
    state.setContactEmail.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_CONTACT_US_EMAIL_FULFILLED: (state, action) => {
    state.setContactEmail.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.setContactEmail.data = action.payload.data;
  },

  SET_CONTACT_US_EMAIL_REJECTED: (state, action) => {
    state.setContactEmail.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.setContactEmail.data = null;
  },
});

export default contactFormReducer;
