import { connect } from 'react-redux';
import AustraliaLanding from './AustraliaLanding';
import { getLocationQA } from '../../../actions';
import { logOut } from '../../../actions/actionsAuth';
import { AppState } from '../../../interfaces';
// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    app: state.appReducer,
    appWidth: state.uiReducer.appWidth,
    user: state.userReducer,
    userLocation: state.locationReducer,
  };
};
const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
  getLocation: (postcode, setSubmitting) => dispatch(getLocationQA(postcode, setSubmitting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AustraliaLanding);
