import store from 'store2';
import * as _ from 'lodash';
import { UserReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';

export const initialState: UserReducer | any = {
  // TODO: TYPESCRIPT FIX
  status: {
    loading: false,
    error: null,
    done: false,
  },
  data: null,
};

let stateFromStore = initialState;
if (_.has(store('swsmUser'), 'status')) {
  stateFromStore = store('swsmUser');
}

const userReducer = createReducer(stateFromStore, {
  CLEAR_USERDATA: state => {
    state.status = {
      loading: false,
      error: null,
      done: false,
    };
    state.data = null;
  },

  POST_USER_DATA_PENDING: state => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
    state.data = null;
  },

  POST_USER_DATA_FULFILLED: (state, action) => {
    const updatedState = {
      ...state,
      status: {
        loading: false,
        error: null,
        done: true,
      },
      data: _.get(action, 'payload.data', null),
    };
    store('swsmUser', updatedState);
    state.data = updatedState;
  },

  POST_USER_DATA_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.errors[0]', i18next.t('portal.authentication.error_message')),
      done: true,
    };
    state.data = null;
  },

  LOG_IN_PENDING: state => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  LOG_IN_FULFILLED: (state, action) => {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Authenticated user ' + _.get(action, 'payload.data.id'),
      level: Sentry.Severity.Info,
    });

    state.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.data = action.payload.data;
    if (process.env.NODE_ENV !== 'production' && module.hot) {
      state.data.rewardsProgram = true;
    }

    store('swsmUser', state);
  },

  LOG_IN_REJECTED: (state, action) => {
    const errorMessage = _.get(action, 'payload');

    console.log(`Backend error message:${errorMessage}`);
    console.log(`Translation key message: ${i18next.t('portal.authentication.error_message')}`);
    state.status = {
      loading: false,
      error: errorMessage ? i18next.t(`${errorMessage}`) : i18next.t('portal.authentication.error_message'),
      done: false,
    };
  },

  REFRESH_ACCESS_TOKEN_PENDING: state => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  REFRESH_ACCESS_TOKEN_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.data.accessToken = _.get(action, 'payload.data.accessToken', null);
    store('swsmUser', state);
  },

  DEBUG_TOGGLE_REWARDS_PROGRAM: (state, action) => {
    if (state.data) {
      state.data.rewardsProgram = action.isVisible;
    }
  },

  DEBUG_TOGGLE_TUTORIAL: (state, action) => {
    if (state.data) {
      state.data.showTutorial = action.isVisible;
    }
  },
});

export default userReducer;
