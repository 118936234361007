import React from 'react';
import Styled from './Card.style';
import { ChartBars, ChartBarNegative, ChartMultiBars } from '../Charts';
import { ChartData } from '../../interfaces';
import Typography from '../Typography';
import { useTranslation, Trans } from 'react-i18next';
import { Popup, Icon } from '@savewatersavemoney/swsm-ui';
import * as _ from 'lodash';
import history from '../../history';
import BackPanel from './BackPanel';

interface CardProps {
  id: number;
  title: string;
  subTitle?: string;
  rows: Array<any>;
  type: string;
  theme?: string | undefined;
  chartData: ChartData;
  desc: string;
  style?: React.CSSProperties;
  isDesktop: boolean;
  displayMoney?: string;
  defaultLanguage: string;
  backPanel: any;
  getPortalQuestions: Function;
}

const Card: React.FC<CardProps> = props => {
  const {
    title,
    subTitle,
    theme,
    id,
    type,
    chartData,
    isDesktop,
    displayMoney,
    defaultLanguage,
    desc,
    backPanel,
    getPortalQuestions,
  } = props;

  const [t] = useTranslation('common');

  const renderRows = () => {
    switch (type) {
      case 'chart-bar':
        return (
          <ChartBars
            id={id}
            key={id}
            theme={theme}
            displayMoney={displayMoney}
            chartData={chartData && chartData}
            defaultLanguage={defaultLanguage}
          />
        );
      case 'chart-multi-bar':
        return (
          <ChartMultiBars
            id={id}
            key={id}
            theme={theme}
            chartData={chartData && chartData}
            defaultLanguage={defaultLanguage}
            isDesktop={isDesktop}
          />
        );
      case 'chart-bar-negative':
        return (
          <ChartBarNegative
            id={id}
            key={id}
            displayMoney={displayMoney}
            theme={theme}
            chartData={chartData && chartData}
          />
        );
      default:
        return <div key={id}>{type} type don't exist</div>;
    }
  };
  const text_translation_title = t(title);
  const text_translation_subTitle = subTitle && t(subTitle);
  const [showPopup, setPopup] = React.useState(false);
  const [flipped, setFlipped] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const frontRef = React.useRef<HTMLDivElement>(null);
  const backRef = React.useRef<HTMLDivElement>(null);

  // const handleFlip = (id: number) => {
  //   setFlipped(prevId => (prevId === id ? null : id));
  // };
  React.useEffect(() => {
    const handleFlipEvent = (e: CustomEvent) => {
      if (e.detail !== id) {
        setFlipped(false);
      }
    };
    window.addEventListener('cardFlip', handleFlipEvent as EventListener);
    return () => {
      window.removeEventListener('cardFlip', handleFlipEvent as EventListener);
    };
  }, [id]);
  React.useEffect(() => {
    if (containerRef.current && frontRef.current && backRef.current) {
      const frontHeight = frontRef.current.offsetHeight;
      const backHeight = backRef.current.offsetHeight;
      containerRef.current.style.height = `${Math.max(frontHeight, backHeight)}px`;
    }
  });
  const handleFlip = () => {
    if (!flipped) {
      const flipEvent = new CustomEvent('cardFlip', { detail: id });
      window.dispatchEvent(flipEvent);
    }
    setFlipped(!flipped);
  };
  const renderButtons = () => {
    return _.map(chartData.links, (el, index) => {
      return (
        <Styled.ButtonComp
          theme={el.type}
          style={{ padding: '8px 16px' }}
          key={index}
          onClick={() => {
            const link = el.link.slice(1);
            if (el.type === 'externalLink') {
              window.open(el.link, '_blank');
            }
            else if (el.type === 'internalLink') {
              history.replace(el.link);
            }
            else {
              handleFlip();
              getPortalQuestions(link);
            }
          }}
        >
          {el.type === 'question' ? null : <Icon icon={el.icon} size={40} />}
          <p style={{ flex: 1, textAlign: 'left', marginLeft: '16px' }}>{t(el.text)}</p>
          <Icon icon="chevronRight" size={16} colorPrimary={'#0b2641'} />
        </Styled.ButtonComp>
      );
    });
  };
  return (
    <Styled.Card
      flipped={flipped}
      className="card"
      isDesktop={isDesktop}
      data-test="Card"
      id={`card-${id}`}
      ref={containerRef}
    >
      <Styled.Panel ref={frontRef}>
        <Typography
          size="4"
          weight={600}
          style={{
            textAlign: 'center',
            fontSize: '20px',
            marginBottom: subTitle ? '0px' : '32px',
          }}
          text={text_translation_title}
          data-test="title"
        />
        {subTitle && (
          <Typography
            size="p"
            style={{
              textAlign: 'center',
              fontSize: '18px',
              marginBottom: '32px',
            }}
            weight={400}
            text={text_translation_subTitle}
          />
        )}
        {desc && desc.length > 0 ? <Styled.Info onClick={() => setPopup(!showPopup)}>i</Styled.Info> : null}

        <Popup
          style={{ width: isDesktop ? '700px' : '100%' }}
          open={showPopup}
          handleClick={() => {
            setPopup(!showPopup);
          }}
          isDesktop={isDesktop}
        >
          {desc &&
            _.map(desc, (el, index) => {
              return (
                <Styled.Desc key={index}>
                  <Trans i18nKey={el} components={{ 1: <br /> }} />
                </Styled.Desc>
              );
            })}
        </Popup>

        <div style={{ position: 'relative' }}>{renderRows()}</div>
        {chartData.links ? <Styled.ButtonWrap>{renderButtons()}</Styled.ButtonWrap> : null}
      </Styled.Panel>
      <Styled.Panel ref={backRef} style={{ transform: 'rotateY(180deg)', background: 'white' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={() => handleFlip()}>
          <Icon icon="times" size={20} style={{ cursor: 'pointer' }} />
        </div>
        <BackPanel data={backPanel} />
      </Styled.Panel>
    </Styled.Card>
  );
};

export default Card;
