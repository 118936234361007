import React from 'react';
import Styled from './UsaLanding.style';
import { ButtonClean, Loader } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import PostcodeForm from '../../PostcodeForm';
import PopUp from '../../PopUp';
import history from '../../../history';
import { useTranslation } from 'react-i18next';
import { getVariable } from '../../../helpers';
import * as _ from 'lodash';
interface UsaLandingProps {
  app: any;
  getLocation: Function;
  portalMenu: Array<any>;
  user: any;
  appWidth: number;
  userLocation: any;
}
const UsaLanding: React.FC<UsaLandingProps> = props => {
  const { getLocation, userLocation, appWidth } = props;
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = React.useState(false);
  const isDesktop = appWidth > 800;
  const url_string = window.location.href;
  const redirect = url_string.includes('redirect=true');
  const location = _.get(props, 'location.pathname');

  const handlePopup = () => {
    history.push('/');
  };

  const swsmInternal = getVariable('mode', 'swsm', false);

  React.useEffect(() => {
    if (userLocation.redirect_require && userLocation.redirect_url !== window.location.origin) {
      setIsOpen(true);
    }
    else {
      setIsOpen(false);
    }
  }, [isOpen, userLocation.redirect_require, userLocation.redirect_url]);

  const integratedStyles = !swsmInternal ? { justifyContent: 'flex-end', alignItems: 'center' } : null;

  if (isOpen && !swsmInternal) {
    return (
      <>
        <Styled.Modal>
          <Styled.ModalContent>
            <Styled.ModalTitle> {userLocation.waterClient} supply water for this postcode.</Styled.ModalTitle>
            <Styled.ModalText>You are now being redirected.</Styled.ModalText>
          </Styled.ModalContent>
          <Loader size={3} />
        </Styled.Modal>
      </>
    );
  }

  return (
    <Styled.Wrap isDesktop={isDesktop}>
      <Row
        className="GWF-landing"
        style={{
          flexDirection: isDesktop ? 'row' : 'column',
          ...integratedStyles,
        }}
      >
        {redirect ? (
          <PopUp isDesktop={isDesktop} setPopUpOpen={() => handlePopup()} isOpen={redirect}>
            <Styled.HeroContent style={{ marginTop: 0 }}>
              <Styled.HeroTitle style={{ marginTop: 0 }}> {t('portal.homepage.redirect_success')}</Styled.HeroTitle>
              <Styled.HeroDescription style={{ paddingRight: isDesktop ? 65 : null }}>
                {t('portal.homepage.redirect_message')}
              </Styled.HeroDescription>
            </Styled.HeroContent>
          </PopUp>
        ) : null}

        <Col md={!swsmInternal ? 12 : 6} lg={!swsmInternal ? 12 : 6} xs={12} sm={12}>
          {!swsmInternal ? (
            <Row>
              <Col sm={12} xs={12} md={6}>
                <Styled.HeroContent style={{ marginTop: '50px', marginLeft: '20px' }}>
                  <Styled.HeroTitle style={{ textAlign: 'left', color: '#0b2641' }}>
                    {t('portal.homepage.header')}
                  </Styled.HeroTitle>
                  <Styled.HeroDescription style={{ textAlign: 'left' }}>
                    {t('portal.homepage.subheader')}
                  </Styled.HeroDescription>
                </Styled.HeroContent>
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Styled.InputWrapper
                  style={{ marginTop: isDesktop ? '70px' : '20px', marginLeft: isDesktop ? '100px' : '0px' }}
                >
                  <PostcodeForm location={location} getLocation={getLocation} userLocation={userLocation} />
                </Styled.InputWrapper>
                <Styled.HeroDescription
                  style={{
                    fontWeight: 600,
                    paddingRight: isDesktop ? 65 : null,
                    marginLeft: isDesktop ? '100px' : '0px',
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                >
                  - or -
                </Styled.HeroDescription>
                <Styled.SignInText
                  isDesktop={isDesktop}
                  style={{
                    marginLeft: isDesktop ? '100px' : '0px',
                    marginTop: isDesktop ? 'auto' : '0px',
                    fontSize: isDesktop ? '20px' : '18px',
                  }}
                >
                  {t('portal.homepage.customer_sign_in')}{' '}
                  <ButtonClean onClick={() => history.push('/login')}>
                    {t('portal.homepage.customer_sign_in_here')}
                  </ButtonClean>
                </Styled.SignInText>
              </Col>
            </Row>
          ) : (
            <>
              <Styled.HeroContent>
                <Styled.HeroTitle>{t('portal.homepage.header')}</Styled.HeroTitle>
                <Styled.HeroDescription style={{ paddingRight: isDesktop ? 65 : null }}>
                  {t('portal.homepage.subheader')}
                </Styled.HeroDescription>
                <Styled.InputWrapper>
                  <PostcodeForm location={location} getLocation={getLocation} userLocation={userLocation} />
                </Styled.InputWrapper>
              </Styled.HeroContent>
              <Styled.SignInText isDesktop={isDesktop}>
                {t('portal.homepage.customer_sign_in')}{' '}
                <ButtonClean onClick={() => history.push('/login')}>
                  {t('portal.homepage.customer_sign_in_here')}{' '}
                </ButtonClean>
              </Styled.SignInText>
            </>
          )}
        </Col>
      </Row>
    </Styled.Wrap>
  );
};

export default UsaLanding;
