import React from 'react';
import { clearFix } from 'polished';
import styled, { css } from 'styled-components';
import { Button } from '@savewatersavemoney/swsm-ui';
import store from 'store2';
import i18next from 'i18next';
import history from '../../history';
import { colors } from '../../assets/variables';

interface StepNavProps {
  disabled?: boolean;
  initial?: boolean;
  handleNext?: (e: any) => void;
  handlePrev?: (e: any) => void;
  disablePrev?: boolean;
  disableNext?: boolean;
}

const StyledStepNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 220px;
  margin: 30px auto 0;
  ${clearFix()}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    margin: 30px 0;
  }
`;

const StyledInitial = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.action};
    color: ${colors.primary};
  }
`;

const StyledBack = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
`;

const StyledNext = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
  background: ${colors.primary};
  ${props => {
    if (props.disabled) {
      return css`
        background: #d8d8d8;
        color: #595959;
      `;
    }
  }};
`;

const resetProgress = () => {
  store('swsm-se', {});
  store('swsmUser', {});
  store.remove('swsmLocation');
  history.push('/');
};

const StepNav: React.FC<StepNavProps> = props => {
  const { initial = false } = props;

  if (initial) {
    return (
      <StyledStepNav>
        {props.handleNext ? (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
            <StyledInitial
              style={{ maxHeight: '54px', minWidth: 230, marginBottom: 16 }}
              id="stepNavInitial"
              onClick={props.handleNext}
            >
              {i18next.t('questions.stepnav_start_qa_confirm')}
            </StyledInitial>
            <StyledInitial
              appearance="OUTLINED"
              id="stepNavInitial"
              colorSecondary="#fff"
              colorPrimary="#0b2641"
              style={{ maxHeight: '54px', minWidth: 230, background: 'white' }}
              onClick={() => resetProgress()}
            >
              {i18next.t('questions.stepnav_cancel_start_qa')}
            </StyledInitial>
          </div>
        ) : (
          ''
        )}
      </StyledStepNav>
    );
  }
  return (
    <StyledStepNav>
      {props.handlePrev ? (
        <StyledBack
          colorPrimary="#0b2641"
          id="stepNavBack"
          appearance="OUTLINED"
          disabled={props.disablePrev}
          onClick={props.handlePrev}
          style={props.handleNext ? { marginRight: '10px', minWidth: '110px' } : null}
        >
          {i18next.t('questions.stepnav_cancel_start_qa')}
        </StyledBack>
      ) : (
        ''
      )}
      {props.handleNext ? (
        <StyledNext
          style={props.handlePrev ? { minWidth: '110px' } : null}
          id="stepNavNext"
          disabled={props.disableNext}
          onClick={props.handleNext}
        >
          {i18next.t('questions.stepnav_qa_next')}
        </StyledNext>
      ) : (
        ''
      )}
    </StyledStepNav>
  );
};

export default StepNav;
