import React from 'react';
import Styled from './AustraliaLanding.style';
import { Loader } from '@savewatersavemoney/swsm-ui';
import { Col, Row } from 'react-styled-flexboxgrid';
import PostcodeForm from '../../PostcodeForm';
import PopUp from '../../PopUp';
import history from '../../../history';
import { useTranslation } from 'react-i18next';
import { getVariable } from '../../../helpers';
import * as _ from 'lodash';

interface AustraliaLandingProps {
  app: any;
  getLocation: Function;
  portalMenu: Array<any>;
  user: any;
  appWidth: number;
  userLocation: any;
}
const AustraliaLanding: React.FC<AustraliaLandingProps> = props => {
  const { getLocation, userLocation, appWidth } = props;
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = React.useState(false);
  const isDesktop = appWidth > 800;
  const url_string = window.location.href;
  const redirect = url_string.includes('redirect=true');
  const location = _.get(props, 'location.pathname');

  const handlePopup = () => {
    history.push('/');
  };

  const swsmInternal = getVariable('mode', 'swsm', false);

  React.useEffect(() => {
    if (userLocation.redirect_require && userLocation.redirect_url !== window.location.origin) {
      setIsOpen(true);
    }
    else {
      setIsOpen(false);
    }
  }, [isOpen, userLocation.redirect_require, userLocation.redirect_url]);

  const integratedStyles = !swsmInternal ? { justifyContent: 'flex-end', alignItems: 'center' } : null;

  if (isOpen && !swsmInternal) {
    return (
      <>
        <Styled.Modal>
          <Styled.ModalContent>
            <Styled.ModalTitle> {userLocation.waterClient} supply water for this postcode.</Styled.ModalTitle>
            <Styled.ModalText>You are now being redirected.</Styled.ModalText>
          </Styled.ModalContent>
          <Loader size={3} />
        </Styled.Modal>
      </>
    );
  }

  return (
    <Styled.Wrap isDesktop={isDesktop}>
      <Row
        className="GWF-landing"
        style={{
          flexDirection: isDesktop ? 'row' : 'column',
          ...integratedStyles,
        }}
      >
        {redirect ? (
          <PopUp isDesktop={isDesktop} setPopUpOpen={() => handlePopup()} isOpen={redirect}>
            <Styled.HeroContent style={{ marginTop: 0 }}>
              <Styled.HeroTitle style={{ marginTop: 0 }}> {t('portal.homepage.redirect_success')}</Styled.HeroTitle>
              <Styled.HeroDescription style={{ paddingRight: isDesktop ? 65 : null }}>
                {t('portal.homepage.redirect_message')}
              </Styled.HeroDescription>
            </Styled.HeroContent>
          </PopUp>
        ) : null}

        <Col md={!swsmInternal ? 12 : 6} lg={!swsmInternal ? 12 : 6} xs={12} sm={12}>
          <Styled.HeroContent>
            <Styled.HeroTitle>{t('portal.homepage.header')}</Styled.HeroTitle>
            <Styled.HeroDescription style={{ paddingRight: isDesktop ? 65 : null }}>
              {t('portal.homepage.subheader')}
            </Styled.HeroDescription>
            <Styled.InputWrapper>
              <PostcodeForm location={location} getLocation={getLocation} userLocation={userLocation} />
            </Styled.InputWrapper>
          </Styled.HeroContent>
        </Col>
      </Row>
    </Styled.Wrap>
  );
};

export default AustraliaLanding;
