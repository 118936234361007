import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  width: 100%;
  color: white;
  margin-bottom: 16px;

  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
  max-height: 245px;
  height: 245px;
`;
const Content = styled.div`
  width: 60%;
  background: #19568a;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 24px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`;
const Stamps = styled.div`
  width: 40%;
  display: flex;

  flex-direction: column;
  padding: 16px;
  align-items: flex-end;

  .win {
    width: 80px;
    height: auto;
  }
  .caroma {
    mix-blend-mode: multiply;
    width: 60px;
    height: auto;
    margin-top: auto;
  }
`;
export default { Wrap, Content, Image, Stamps };
