import styled from 'styled-components';
// import ButtonComponent from '../../Button';
import { Button } from '@savewatersavemoney/swsm-ui';
import { QAcolors } from '../../../assets/variables';

export const Wraper = styled.div`
  max-width: 220px;
  flex: 0 0 220px;
`;

export const Row = styled.div`
  margin-bottom: 10px;
`;

export const Image = styled.img`
  border: 1px solid #eaeaea;
  margin-top: 20px;
  height: 150px;
  width: 150px;
`;

export const Desc = styled.div`
  color: ${QAcolors.darkGray};
  font-size: 14px;
  margin: 5px 10px 12px 10px;
`;

export const ButtonComponent = styled(Button)`
  text-align: center;
  display: inline-block;
  width: 100%;

  flex: 0 0 100%;
`;

export default {
  Wraper,
  Button,
  Row,
  Image,
  Desc,
};
