import { SuggestiveSearchReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
const initialState: SuggestiveSearchReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};
const suggestiveSearchReducer = createReducer(initialState, {
  GET_SUGGESTION_SEARCH_PENDING: state => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_SUGGESTION_SEARCH_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },
  GET_SUGGESTION_SEARCH_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: action.payload.data.detail,
      done: false,
    };
  },
  CLEAR_SUGGESTION_SEARCH: state => {
    state.status = {
      loading: false,
      error: false,
      done: false,
    };
    state.data = null;
  },
});
export default suggestiveSearchReducer;
