import { AppState } from '../../interfaces';
import { connect } from 'react-redux';
import Card from './Card';
import { getPortalQuestions } from '../../actions/index';
const mapStateToProps = (state: AppState) => ({
  backPanel: state.portalQuestionsReducer,
});
const mapDispatchToProps = dispatch => ({
  getPortalQuestions: url => dispatch(getPortalQuestions(url)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Card);
