import React, { Component } from 'react';
import StepNav from '../../StepNav';
import { ChapterProps } from '../../../interfaces';
import Progress from '../../Pages/Progress';
import Styled from './Chapter.style';
import Typography from '../../Typography';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { getVariable } from '../../../helpers';
import i18next from 'i18next';

declare global {
  interface Window {
    _paq: any;
  }
}

class Chapter extends Component<ChapterProps, any> {
  private questionRef = React.createRef<HTMLDivElement>();

  constructor(ChapterProps) {
    super(ChapterProps);

    this.state = {
      disableNextNav: false,
    };
  }

  componentDidMount() {
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
  }

  componentDidUpdate() {
    if (this.questionRef.current && this.props.containerHeight !== this.questionRef.current.clientHeight) {
      this.updateQuestionHeight();
    }
  }

  updateQuestionHeight() {
    if (this.questionRef.current) {
      this.props.setQuestionHeight(this.questionRef.current.clientHeight);
    }
  }

  handlePrev = () => {
    this.props.goToPrev();
  };

  handleNext = () => {
    const swsmInternal = getVariable('mode', 'swsm', false);
    const australia = getVariable('client', 'waterClient', 'aus');
    this.props.goToNext();
    if (this.props.currentStep === 0 && swsmInternal && australia !== 'usa') {
      window._paq.push(['trackEvent', 'qa', 'qa started']);
    }
  };

  handleStepnav = () => {
    const { currentStep } = this.props;
    return (
      <StepNav
        handlePrev={currentStep !== 0 ? this.handlePrev : undefined} //Hide if 1st question
        handleNext={this.handleNext}
        initial={currentStep === 0}
      />
    );
  };

  render() {
    const { id, title, text, show_progress } = this.props.data;
    const textWithBreaks = text.replace(/\\n/g, '<br />');
    const title_key = i18next.t(title);
    const description_key = i18next.t(textWithBreaks);
    const noteText = i18next.t(this.props.data.note);
    const isDesktop = this.props.appWidth > 800;

    return (
      <Styled.Wraper isDesktop={isDesktop} ref={this.questionRef}>
        <Styled.Container id={id}>
          {this.props.data.note ? (
            <Typography size="p" text={noteText} style={{ fontWeight: 600, color: '#FF4136' }} />
          ) : null}
          <Icon
            size={200}
            style={{ marginBottom: isDesktop ? '32px' : '20px', marginLeft: 'auto', marginRight: 'auto' }}
            icon="droplet"
          />
          <Typography size="3" text={title_key} />
          {/* TODO: add showProgress into database */}
          {show_progress && <Progress />}
          <Styled.Question style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: description_key }} />

          {this.handleStepnav()}
        </Styled.Container>
      </Styled.Wraper>
    );
  }
}

export default Chapter;
